import { sc, tlReadyExecute } from '@doda/common/src/utils/adobeAnalyticsUtil';
import { dataLayer } from '@doda/common/src/utils/googleTagManagerUtil';
import { AppThunk } from '@doda/common/src/redux/store';
import { adobeAnalyticsScriptsSelector } from '@doda/common/src/redux/features/analytics';
import { Result as RecommendJob } from '@doda/common/src/interfaces/api/recommendPanel';
import { getItemNoStringInRange } from '../../../JobHistoryList/logics/getIdStringInRange';
import type { Todo } from '../../components/TodoList';
import { TodoIconType } from '../../components/TodoListCard';

/**
 * ページ名を取得する。ログイン状態によってテキストが変わる。
 * @param isLoggedIn ログイン状態
 * @returns ページ名
 */
const getPageName = (isLoggedIn: boolean): string =>
  `PC_ログイン${isLoggedIn ? '後' : '前'}トップ`;

/**
 * PV計測(AA)
 * @param isLoggedIn ログイン状態
 */
export function measureImpressionTopAA(isLoggedIn: boolean) {
  sc({
    channel: '/',
    pageName: getPageName(isLoggedIn),
  });
}

/**
 * PV計測(GA)
 * @param isLoggedIn ログイン状態
 */
export function measureImpressionTopGA(isLoggedIn: boolean) {
  const pageName = getPageName(isLoggedIn);
  dataLayer({
    PageName: pageName,
    Channel: pageName,
  });
}

/**
 * レコメンド求人が画面内に表示されたときの計測
 * @param recommendType レコメンド種別
 * @param recommendJobList 表示されたレコメンド求人リスト
 * @returns thunkアクション
 */
export function measureRecommendVisible(
  recommendType: string,
  recommendJobList: RecommendJob[]
): AppThunk<void> {
  return (dispatch, getState) => {
    const isAdobeScript = adobeAnalyticsScriptsSelector(getState());

    const jobCount = recommendJobList?.length ?? 0;
    const linkTrackVars = 'eVar181,eVar182,eVar183,eVar184';

    if (!recommendJobList) {
      tlReadyExecute(
        isAdobeScript,
        dispatch,
        `recommendVisible:${recommendType}`,
        {
          linkTrackVars,
          eVar181: `${jobCount}`,
        }
      );
      return;
    }

    const lastIndex = recommendJobList.length - 1;

    tlReadyExecute(
      isAdobeScript,
      dispatch,
      `recommendVisible:${recommendType}`,
      {
        linkTrackVars,
        eVar181: `${jobCount}`,
        ...(recommendJobList.length > 1 && {
          eVar182: getItemNoStringInRange(recommendJobList, 0, 22),
        }),
        ...(recommendJobList.length > 23 && {
          eVar183: getItemNoStringInRange(recommendJobList, 23, 45),
        }),
        ...(recommendJobList.length > 46 && {
          eVar184: getItemNoStringInRange(recommendJobList, 46, lastIndex),
        }),
      }
    );
  };
}

/**
 * フリーワード計測
 * @returns thunkアクション
 */
export function measureFreeWord(): AppThunk<void> {
  return (dispatch, getState) => {
    const isAdobeScript = adobeAnalyticsScriptsSelector(getState());
    tlReadyExecute(isAdobeScript, dispatch, 'FW_SRCHsgt_imp', {});
  };
}

/**
 * チャットクリック計測
 * @returns thunkアクション
 */
export function measureChatClick(): AppThunk<void> {
  return (dispatch, getState) => {
    const isAdobeScript = adobeAnalyticsScriptsSelector(getState());
    tlReadyExecute(isAdobeScript, dispatch, 'chat click', {});
  };
}

/**
 * Google Analytics IDの末尾計測
 * @param googleAnalyticsId
 * @returns thunkアクション
 */
export function measureGoogleAnalyticsIdLastNumber(
  googleAnalyticsId: string
): AppThunk<void> {
  return (dispatch, getState) => {
    const isAdobeScript = adobeAnalyticsScriptsSelector(getState());
    const idLastNumber = parseInt(
      googleAnalyticsId[googleAnalyticsId.length - 1],
      10
    );
    tlReadyExecute(
      isAdobeScript,
      dispatch,
      `gaid_LastNumber_${idLastNumber < 5 ? '0-4' : '5-9'}`,
      {}
    );
  };
}

/**
 * レコメンド求人気になるクリック計測
 * @param jobId 求人id
 * @returns thunkアクション
 */
export function measureRecommendBookmarkButtonClick(
  jobId: string
): AppThunk<void> {
  return (dispatch, getState) => {
    const isAdobeScript = adobeAnalyticsScriptsSelector(getState());

    const eventId = 'event120';
    const props23 = 'kininaru_Click';

    tlReadyExecute(isAdobeScript, dispatch, 'kininaruClick_TOP_rec', {
      linkTrackVars:
        'channel,events,products,prop1,prop23,prop32,prop69,eVar23,eVar78',
      linkTrackEvents: 'event120',
      events: eventId,
      products: `;${jobId}`,
      prop23: props23,
      prop32: jobId,
      eVar23: props23,
    });
  };

  dataLayer({
    click_Action: 'Click',
    click_Category: 'bookmark',
    JobId: jobId,
    event: 'bookmark_click',
  });
}

/**
 * レコメンド求人クリック
 * @param recommendJob レコメンド種別
 * @param isLoggedIn ログイン状態
 * @returns thunkアクション
 */
export function measureClickRecommend(
  recommendJob: RecommendJob,
  isLoggedIn: boolean
): AppThunk<void> {
  return (dispatch, getState) => {
    const { recommendType } = recommendJob;
    const isAdobeScript = adobeAnalyticsScriptsSelector(getState());

    const eVar104 = `EtsuranRireki_Rekome_${recommendType}_pc`;
    const pageName = getPageName(isLoggedIn);

    tlReadyExecute(isAdobeScript, dispatch, 'siteRecommendTracking', {
      linkTrackVars: 'channel',
      linkTrackEvents: 'None',
      eVar103: recommendType,
      eVar104,
      eVar105: pageName,
      eVar187: pageName,
    });
  };
}

/**
 * todoリストインプレッション計測
 * @param todoList todoリスト
 * @returns thunkアクション
 */
export function measureImpressionTodoListAA(todoList: Todo[]): AppThunk<void> {
  return (dispatch, getState) => {
    const todoTypeMapping: Record<TodoIconType, string> = {
      counselingDate: 'cRF',
      dateSetting: 'iDS',
      interviewDateSetting: 'iDC',
      message: 'mB',
      resumeFileUpload: 'p',
      counseling: 'cR',
      desiredCondition: 'dS',
    };
    const uniqueTodoTypeString = todoList
      .reduce<string[]>((previousValue, currentValue) => {
        const param = todoTypeMapping[currentValue.iconType];
        if (param && previousValue.includes(param)) {
          return previousValue;
        }
        return [...previousValue, param];
      }, [])
      .join('_');
    const isAdobeScript = adobeAnalyticsScriptsSelector(getState());
    tlReadyExecute(
      isAdobeScript,
      dispatch,
      `PC_loginHome_todoArea_${todoList.length}_${uniqueTodoTypeString}_imp`,
      {}
    );
  };
}

/**
 * todoリストクリック計測
 * @param todoList todoリスト
 * @param clickedTodo クリックされたtodo
 * @returns thunkアクション
 */
export function measureTodoListClickAA(
  todoList: Todo[],
  clickedTodo: Todo
): AppThunk<void> {
  return (dispatch, getState) => {
    const todoTypeMapping: Record<TodoIconType, string> = {
      counselingDate: 'cRF',
      dateSetting: 'iDS',
      interviewDateSetting: 'iDC',
      message: 'mB',
      resumeFileUpload: 'p',
      counseling: 'cR',
      desiredCondition: 'dS',
    };
    const uniqueTodoTypeString = todoList
      .reduce<string[]>((previousValue, currentValue) => {
        const param = todoTypeMapping[currentValue.iconType];
        if (param && previousValue.includes(param)) {
          return previousValue;
        }
        return [...previousValue, param];
      }, [])
      .join('_');
    const isAdobeScript = adobeAnalyticsScriptsSelector(getState());
    tlReadyExecute(
      isAdobeScript,
      dispatch,
      `PC_loginHome_todoArea_${todoList.length}_${uniqueTodoTypeString}_imp_${clickedTodo.iconType}_click`,
      {}
    );
  };
}
